<template>
 <div class="container-fluid" style="overflow-x: hidden;">
    <div class="row" style="min-height: 100vh;">
      <div class="col-lg-5 login-side-col bg-primary">
        <div class="row h-100">
          <div class="col-12 align-self-center">
            <img class="my-auto align-self-center" :src="require('@/assets/images/logo-ae-w-alt.png')" />
          </div>
        </div>
      </div>
      <div class="col-lg-7 align-self-center h-100" style="">
          <div clas="row justify-content-center">
            <div class="col-12 text-center">
                <div class="lds-dual-ring"></div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Logout',
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {
        this.$auth.logout();
    }
}
</script>
